application.register("part_form_booking", class extends Stimulus.Controller {
    get ref() {
        return {
            arrival: this.targets.find("arrival"),
            arrivalAlt: this.targets.find("arrivalAlt"),
            departure: this.targets.find("departure"),
            departureAlt: this.targets.find("departureAlt"),
        }
    }
    connect() {
        const self = this;

        cssLoaded(()=>{
            self.createDatepickers();
        });
    }

    createDatepickers() {
        const self = this;
        let lang = document.documentElement.getAttribute("lang");

        if(!document.documentElement.classList.contains("ie")) {
            let i18n = (lang === "en") ? cdnjs.datepicker_i18n : cdnjs.datepicker_i18n.replace("/cs.",`/${lang}.`);
            importScript([cdnjs.datepicker, i18n],() => {
                importStyle(cdnjs.datepicker_css);
                const options = {
                    autohide: true,
                    language: lang,
                    minDate: new Date(),
                    format: 'dd.mm.yyyy'
                }
                const dpArrival = new Datepicker(self.ref.arrival,options);
                const dpDeparture = new Datepicker(self.ref.departure,options);

                self.ref.arrival.addEventListener("changeDate",()=>{
                    let date = dpArrival.getDate("yyyy-mm-dd");
                    let date_next = new Date(new Date(date));
                    date_next.setDate(date_next.getDate() + 1);
                    self.ref.arrivalAlt.value = date;
                    dpDeparture.setOptions({
                        minDate: date_next
                    })
                    dpDeparture.setDate(date_next);
                });
                self.ref.departure.addEventListener("changeDate",()=>{
                    self.ref.departureAlt.value = dpDeparture.getDate("yyyy-mm-dd");
                });

                dpArrival.setDate(new Date());
            });
        }
        else {
            let i18n = (lang === "ru") ? cdnjs.airdatepicker_i18n : cdnjs.airdatepicker_i18n.replace(".cs.",`.${lang}.`);
            importScript([cdnjs.jquery, cdnjs.airdatepicker, i18n],() => {
                importStyle(cdnjs.airdatepicker_css);
                const options = {
                    autoClose: true,
                    language: lang,
                    minDate: new Date(),
                    startDate: new Date(),
                    dateFormat: 'dd.mm.yyyy',
                    altFieldDateFormat: 'yyyy-mm-dd',
                    position: "top center",
                }

                let dpArrival, dpDeparture;

                dpArrival = $(self.ref.arrival).datepicker($.extend({},options,{
                    altField: self.ref.arrivalAlt,
                    onSelect: function (fd,d,inst) {
                        let date_next = new Date(new Date(d));
                        date_next.setDate(date_next.getDate() + 1);
                        dpDeparture.update({
                            startDate: date_next,
                            minDate: date_next
                        });
                        dpDeparture.selectDate(date_next);
                    }
                })).data('datepicker');

                dpDeparture = $(self.ref.departure).datepicker($.extend({},options,{
                    altField: self.ref.departureAlt
                })).data('datepicker');

                dpArrival.selectDate(new Date());
            });
        }
    }
});