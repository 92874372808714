if (typeof Swup !== "undefined") {
    const swup = new Swup({
        containers: ['#layout_main', '#layout_header', '#layout_social', '#layout_footer', '#layout_special']
    });

    swup.on('clickLink', function () {
        document.body.classList.remove("overflow--hidden");

        if (document.querySelector(".drawer-active") !== null) {
            application["getControllerForElementAndIdentifier"](document.body, "lib-drawer").hide()
        }

        if (document.querySelector(".lib--dialog") !== null) {
            LibDialog.hide();
        }
    });

    document.addEventListener('swup:animationOutDone', () => {
        window.scrollTo(0, 0);

        if (typeof window.swupActive === "undefined") {
            window.swupActive = true;
        }
    });

    swup.on('contentReplaced', function () {
        if (typeof locomotive !== "undefined") {
            locomotive.destroy();
            document.getElementById("layout_scroll").removeAttribute("style");
            if (document.querySelector("[data-no-locomotive]") === null) {
                locomotive.init();
            }
        }
        window.scrollTo(0, 0);

        swup.options.containers.forEach((selector) => {
            loadStimulus(document.querySelector(selector));
        });

        let swupReloadScripts = [...document.body.querySelectorAll("[data-swup-reload-script]")];
        if (swupReloadScripts.length > 0) {
            swupReloadScripts.forEach((script) => {
                const element = document.createElement('script');

                for (const {name, value} of [...script.attributes]) {
                    element.setAttribute(name, value);
                }
                element.textContent = script.textContent;
                element.setAttribute('async', 'false');

                script.replaceWith(element);
            });
        }

        LibAnchor.init();

        if(typeof window.luiCookieConsentInit !== 'undefined') {
            window.luiCookieConsentInit()
        }

        if (typeof fbq !== "undefined") {
            fbq('track', 'PageView');
        }

        if (typeof gtag !== "undefined") {
            let configs = [];
            window.dataLayer.forEach(function (config) {
                if (config[0] === "config") {
                    if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                        configs.push(config[1]);
                        gtag('config', config[1], {
                            'page_title': document.title,
                            'page_path': window.location.pathname + window.location.search
                        });
                    }
                }
            });
        }

        if (typeof window.dataLayer !== "undefined") {
            window.dataLayer.push({
                'event': 'VirtualPageview',
                'virtualPageURL': window.location.pathname + window.location.search,
                'virtualPageTitle': document.title
            });
        }

        if (typeof window.seznam_retargeting_id !== "undefined") {
            importScript("https://c.imedia.cz/js/retargeting.js");
        }

        if (typeof LibCookieConsent !== "undefined") {
            LibCookieConsent.init();
        }

        document.body.classList.remove("body--preload");
        document.body.classList.add("body--loaded");
    });
}
